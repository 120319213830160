import { useMediaQuery } from "@/src/hooks/useMediaQuery";
import {
  ToastContainer,
  toast,
  UpdateOptions,
  cssTransition,
} from "react-toastify";
import { useEffect, useContext, useMemo, useRef } from "react";
import { PlannerContext } from "@/contexts/PlannerContext";
import ShoppingListButton from "./ShoppingListButton";
import ShoppingList from ".";
import fetchGetPlannersByUserId from "@/lib/planner/fetchGetPlannersByUserId";
import { useStores } from "@/hooks/useStores";
import fetchAddRecipeToPlanner from "@/lib/planner/fetchAddRecipeToPlanner";
import fetchCreatePlanner from "@/lib/planner/fetchCreatePlanner";
import fetchUpdatePlannerDays from "@/lib/planner/fetchUpdatePlannerDays";
import { getMediaQuery } from "@/lib/utils";
import "animate.css";
import { useFeatureFlagEnabled } from "posthog-js/react";

export const SHOPPING_LIST_GUID = "03f098dd-5a1a-4244-9106-1a7fdd7d0179";

export default function Container({ hideOnScroll = false }) {
  const { authStore } = useStores();
  const { currentUser } = authStore;
  const flagEnabled = useFeatureFlagEnabled(
    "PMF-WEB-06_Add_Recipes_Directly_to_Shopping_List"
  );

  if (currentUser?.plan !== 2 || !flagEnabled) return null;

  return (
    <ShoppingListContainer
      hideOnScroll={hideOnScroll}
      currentUser={currentUser}
    />
  );
}

function ShoppingListContainer({ hideOnScroll, currentUser }) {
  const isToastActive = useRef(null);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const {
    planners,
    isShoppingListOpen,
    setPlanners,
    setLastFetchedAt,
    lastFetchedAt,
  } = useContext(PlannerContext);
  const lastScrollY = useRef(0);

  const bounce = cssTransition({
    enter: isMobile
      ? "animate__animated animate__faster animate__bounceInUp"
      : "animate__animated animate__faster animate__bounceInRight",
    exit: isMobile
      ? "animate__animated animate__faster animate__bounceOutDown"
      : "animate__animated animate__faster animate__bounceOutRight",
  });

  const toastProps = {
    autoClose: false,
    className:
      "p-0 pb-4 xs:pb-0 m-0 box-content shadow-none border-none bg-transparent w-[342px]",
    closeButton: false,
    closeOnClick: false,
    position: isMobile ? "bottom-center" : "bottom-right",
    toastId: "shopping-list-toast",
    draggable: false,
    transition: bounce,
  } as UpdateOptions<unknown>;

  const shoppingList = useMemo(() => {
    return planners.find((planner) => planner.title === SHOPPING_LIST_GUID);
  }, [planners]);

  const getInitialPlanners = async () => {
    try {
      const fetchedPlanners = await fetchGetPlannersByUserId(currentUser.id, {
        isHidden: true,
      });
      setPlanners(fetchedPlanners);
      setLastFetchedAt(new Date());
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (isMobile != undefined) {
      if (isToastActive.current) {
        toast.update(toastProps.toastId, toastProps);
      } else {
        toast.onChange((payload) => {
          if (payload.id === toastProps.toastId && payload.status === "added")
            isToastActive.current = true;
          if (payload.id === toastProps.toastId && payload.status === "removed")
            isToastActive.current = false;
        });
        toast(<ShoppingListButton />, toastProps);
      }
    }
  }, [isMobile]);

  useEffect(() => {
    if (isShoppingListOpen) {
      toast.dismiss(toastProps.toastId);
    } else if (isToastActive.current) {
      const intervalId = setInterval(() => {
        if (!isToastActive.current) {
          clearInterval(intervalId);
          toast(<ShoppingListButton />, toastProps);
        }
      }, 100);
    } else if (isMobile != undefined) {
      toast(<ShoppingListButton />, toastProps);
    }
  }, [isShoppingListOpen]);

  useEffect(() => {
    if (!currentUser) return;

    getInitialPlanners();
  }, [currentUser]);

  useEffect(() => {
    if (!hideOnScroll) return;

    const SCROLL_BUFFER = 3; // prevents triggering off microscrolls

    const handleScroll = () => {
      const currentScrollY = Math.max(window.scrollY, 0);

      /*
        There is usually a slight delay between the toast being dismissed and the toast 
        being removed from the isToastActive.current check i.e. when the dialog opens

        We want to prevent this function from firing when the dialog is opened
      */
      if (toast.isActive(toastProps.toastId) !== isToastActive.current) return;

      if (currentScrollY > lastScrollY.current + SCROLL_BUFFER) {
        // Scrolling down
        toast.dismiss(toastProps.toastId);
      } else if (currentScrollY < lastScrollY.current - SCROLL_BUFFER) {
        // Scrolling up
        toast(<ShoppingListButton />, toastProps);
      }

      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hideOnScroll, isMobile]);

  return (
    <>
      <ToastContainer />
      <ShoppingList shoppingList={shoppingList} />
    </>
  );
}

export const addRecipeToShoppingList = async ({
  recipe,
  shoppingList,
  currentUser,
}) => {
  const isMobile = getMediaQuery("(max-width: 768px)");

  if (shoppingList) {
    try {
      const response = await fetchAddRecipeToPlanner(
        recipe.id,
        shoppingList.uid,
        currentUser.id,
        {
          plannerDayUid: shoppingList.days[0].uid,
        }
      );

      toast(<ShoppingListButton />, {
        autoClose: false,
        className:
          "p-6 m-0 box-content shadow-none border-none bg-transparent w-[342px]",
        closeButton: false,
        closeOnClick: false,
        position: isMobile ? "bottom-center" : "bottom-right",
        toastId: "shopping-list-toast",
        draggable: false,
      });

      return response;
    } catch (error) {
      console.error("Error:", error);
    }
  } else {
    try {
      const response = await fetchCreatePlanner(
        SHOPPING_LIST_GUID,
        {
          id: currentUser.id,
          uid: currentUser.uid,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email,
        },
        false,
        {
          recipeIds: [recipe.id],
          isHidden: true,
        }
      );

      toast(<ShoppingListButton />, {
        autoClose: false,
        className:
          "p-6 m-0 box-content shadow-none border-none bg-transparent w-[342px]",
        closeButton: false,
        closeOnClick: false,
        position: isMobile ? "bottom-center" : "bottom-right",
        toastId: "shopping-list-toast",
        draggable: false,
      });

      return response;
    } catch (error) {
      console.error("Error:", error);
    }
  }
};

export const removeRecipeFromShoppingList = async ({
  shoppingList,
  recipe,
}) => {
  const newDays = shoppingList.days.map((plannerDay) => {
    return {
      ...plannerDay,
      dayRecipes: plannerDay.dayRecipes.filter(
        (dayRecipe) => dayRecipe.recipeId.toString() !== recipe.id.toString()
      ),
    };
  });

  const updatedDays = await fetchUpdatePlannerDays(shoppingList.uid, newDays);

  return { ...shoppingList, days: updatedDays };
};

export const removeAllRecipesFromShoppingList = async ({ shoppingList }) => {
  const newDays = shoppingList.days.map((plannerDay) => {
    return {
      ...plannerDay,
      dayRecipes: [],
    };
  });

  const updatedDays = await fetchUpdatePlannerDays(shoppingList.uid, newDays);

  return { ...shoppingList, days: updatedDays };
};
